import { inject, Injectable } from '@angular/core';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import {
  BehaviorSubject,
  forkJoin,
  from,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
} from 'rxjs';
import { AUTH_MATRIX } from 'src/app/data/authMatrix';
import { CompanyService, CompanyType } from './company.service';
import { CompanyFeatureType, FeatureService } from './feature.service';
import { User, UserService } from './user.service';
import { ContractTermsService } from './contract-terms.service';

export interface RoleFeatureTuple {
  role: CompanyType | '*';
  featureType: CompanyFeatureType | '*';
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private keycloak = inject(KeycloakService);
  private userService = inject(UserService);
  private companyService = inject(CompanyService);
  private featureService = inject(FeatureService);
  private contractTermsService = inject(ContractTermsService);
  public isLoggedIn$ = of(this.keycloak.isLoggedIn());
  public userProfile$ = this.isLoggedIn$.pipe(
    switchMap((isLoggedIn) => {
      if (!isLoggedIn) return of(null);
      return from(this.keycloak.loadUserProfile());
    }),
    shareReplay()
  );
  public userProfile = null;

  public user$: Observable<null | User> = this.userProfile$.pipe(
    switchMap((userProfile) => {
      if (!userProfile) return of(null);
      return this.userService.getUserByKeycloakId(userProfile.id);
    })
  );

  public company$ = this.user$.pipe(
    switchMap((user) => {
      if (!user) return of(null);
      return this.companyService.getCompany(user.companyId);
    })
  );

  public isWasteProducer$ = this.company$.pipe(
    map((company) => company && company.types.includes('WASTE_PRODUCER'))
  );

  public isWasteDisposer$ = this.company$.pipe(
    map(
      (company) =>
        company &&
        (company.types.includes('WASTE_DISPOSAL') ||
          company.types.includes('WASTE_COLLECTOR'))
    )
  );

  public isWasteConsultant$ = this.company$.pipe(
    map((company) => company && company.types.includes('WASTE_CONSULTANT'))
  );

  public isWasticsAdmin$ = this.isLoggedIn$.pipe(
    map((isLoggedIn) => {
      if (!isLoggedIn) return false;
      return this.keycloak.getUserRoles().includes('wastics_admin');
    })
  );

  public isUserVerified$ = this.user$.pipe(map((user) => user?.verified));

  public isCompanyVerified$ = this.company$.pipe(
    map((company) => company?.verified)
  );

  public isMissingRequiredContractTerms$ = new BehaviorSubject(false);

  public fetchContractTerms() {
    forkJoin([this.isWasteDisposer$, this.company$])
      .pipe(
        switchMap(([isWasteDisposer, company]) => {
          if (!isWasteDisposer) return of(false);
          return this.contractTermsService.companyIsMissingContractTerms(
            company.id
          );
        })
      )
      .subscribe((result) => {
        this.isMissingRequiredContractTerms$.next(result);
      });
  }

  public canPostOffers$ = this.company$.pipe(
    switchMap((company) =>
      this.contractTermsService
        .getOriginalContractTermsForCompany(company.id)
        .pipe(map((terms) => terms?.status === 'VERIFIED'))
    )
  );

  public isVerified$ = forkJoin([
    this.isUserVerified$,
    this.isCompanyVerified$,
  ]).pipe(
    map(
      ([isUserVerified, isCompanyVerified]) =>
        isCompanyVerified && isUserVerified
    )
  );

  canActivatePath(path: string) {
    const allowedTuples = AUTH_MATRIX[path];

    if (!allowedTuples)
      throw new Error('Path cannot be found in auth matrix: ' + path);

    return forkJoin(
      allowedTuples.map((tuple: RoleFeatureTuple) =>
        this.hasRoleAndFeature(tuple)
      )
    ).pipe(
      map((results: boolean[]) => results.reduce((acc, cur) => acc || cur))
    );
  }

  public hasFeature(featureType: CompanyFeatureType): Observable<boolean> {
    return this.company$.pipe(
      switchMap((company) => {
        if (!company) return of(false);

        return this.featureService.getFeaturesForCompany(company.id).pipe(
          map((features) => {
            if (!features?.features) return false;
            return features.features.some(
              (feature) => feature.type === featureType
            );
          })
        );
      })
    );
  }

  public hasRoleAndFeature({
    role,
    featureType,
  }: RoleFeatureTuple): Observable<boolean> {
    return this.company$.pipe(
      switchMap((company) => {
        if (!company) return of(false);

        return this.featureService.getFeaturesForCompany(company.id).pipe(
          map((features) => {
            let hasFeature = false;
            let hasRole = false;

            if (!features?.features) return false;

            if (role === '*') hasRole = true;
            else hasRole = company.types.includes(role);

            if (featureType === '*') hasFeature = true;
            else
              hasFeature = features.features.some(
                (feature) => feature.type === featureType
              );

            return hasRole && hasFeature;
          })
        );
      })
    );
  }

  constructor() {
    this.isLoggedIn$.subscribe((isLoggedIn) => {
      if (!isLoggedIn) {
        this.keycloak.login();
      }
    });

    this.userProfile$.subscribe(
      (userProfile) => (this.userProfile = userProfile)
    );

    this.keycloak.keycloakEvents$.subscribe((event) => {
      // Refresh expired keycloak keys
      if (event.type === KeycloakEventType.OnTokenExpired) {
        this.keycloak.updateToken(20);
      }
    });

    this.fetchContractTerms();
  }
}
